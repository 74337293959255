


























import {Vue, Component, Watch} from "vue-property-decorator";
import ZoomPage from "../../../impView/PageSafety";
import NewFriendsType from "./NewFriends";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import NewFriendsItem from "@/views/Friend/NewFriends/components/Item/index.vue";
import {MessageStore} from "@/views/Message/Store";
import {FriendsApplyList} from "@/views/Message/Server";

@Component({name: "NewFriends",components:{ HeadTop,PullDownUpList,ListBottom,NewFriendsItem }})
export default class NewFriends extends ZoomPage implements NewFriendsType{
    addIcon = require("@/assets/icon/Basics/Add.png")
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []

    mounted(){
        this.loadingShow = false
    }

    activated(){
        this.List = this.getNewFriendsUnread
    }

    handlePullDown(){
        this.PullDown = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        FriendsApplyList().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            MessageStore.SetNewestMessageFriendStatus(res)
        })
    }

    get getNewFriendsUnread(){
        return MessageStore.getNewestMessageFriendStatus
    }
    @Watch("getNewFriendsUnread")
    changeNewFriends(newVal:any[]){
        this.List = newVal
    }
}
