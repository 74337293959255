
























import {Vue, Component, Prop} from "vue-property-decorator";
import NewFriendsItemType from "./indexType";

@Component({name: "NewFriendsItem"})
export default class NewFriendsItem extends Vue implements NewFriendsItemType{

    handleToPath(){
        this.$router.push({
            name:"addFriend",
            query:{
                id:this.getData && this.getData.userId
            }
        })
    }

    get getPhoto(){
        return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png")
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }
}
